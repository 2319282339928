<script setup lang="ts">
const navItems = useNettbutikkNavItems()

useHead({
  htmlAttrs: {
    class: 'bg-blurple-500',
  },
})
</script>

<template>
  <LayoutContainer class="dark-mode">
    <NettbutikkHeaderJuridikaCta />
    <Header v-bind="navItems" narrow solid />
    <NettbutikkHeaderBreadcrumbsAndLogout />
    <main class="flex-1">
      <slot />
    </main>
    <NettbutikkFooterCta />
    <Footer class="dark-mode" wide />
  </LayoutContainer>
</template>
